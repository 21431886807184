<template>

  <div class="add-popup-comp">
    <div class="title van-hairline--bottom">
      {{ title }}
    </div>
    <div>
      <van-radio-group v-model="radioCopy">
        <van-cell-group>
          <van-cell v-for="(option, i) in options"
                    :key="i"
                    :title="option.text"
                    clickable
                    @click="select(option)">
            <van-radio slot="right-icon"
                       :name="option.value" />
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
  </div>

</template>

<script>
export default {
  name: 'stamp-type-comp',
  props: {
    title: {
      type: String,
      default: () => '用章类型'
    },
    options: Array,
    radio: String
  },
  data() {
    return {
      radioCopy: []
    }
  },
  methods: {
    select(options) {
      console.log(options)
      this.radioCopy = options.value
      this.$emit('select', options)
    }
  },
  created() {
    this.radioCopy = this.radio
  }
}
</script>

<style lang='stylus'>
.add-popup-comp
  position relative
  padding-bottom 10px
  box-sizing border-box
  & .title, .popup-item__option
    text-align center
    height 40px
    line-height 40px
    font-size 16px
    &.active
      color #1989fa
  & .title
    color rgba(69, 90, 100, 0.6)
</style>
