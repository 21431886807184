<template>
  <div class="case-list-comp">
    <div>
      <van-search
        v-model="caseName"
        placeholder="请输入搜索关键词"
        @input="searchInput"
      />
    </div>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="!isRequest"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <!-- <div v-for="(option, i) in caseList"
             :key="i">
          {{option.label}}
        </div> -->
        <div>
          <van-radio-group v-model="caseIdCopy">
            <van-cell-group>
              <van-cell
                v-for="(option, i) in caseList"
                :key="i"
                :title="option.label"
                clickable
                @click="select(option)"
              >
                <van-radio slot="right-icon" :name="option.caseId" />
              </van-cell>
            </van-cell-group>
          </van-radio-group>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import caseType from '@/common/caseType'

export default {
  name: 'case-list-comp',
  props: {
    title: {
      type: String,
      default: () => '案件列表'
    },
    caseId: Number,
    isFinish: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isRequest: true, // 是否可以继续请求
      loading: true, // 是否处于加载状态
      refreshing: false, // 是否处于下拉刷新状态
      caseIdCopy: 0, // 案件ID
      caseList: [], // 案件列表
      caseName: '', // 案件名称
      page: 0,
      size: 10
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.userInfo.id
    })
  },
  methods: {
    select(options) {
      var caseTypeName = caseType.filter(
        item => item.value === options.caseTypeValueId
      )[0].label
      this.caseIdCopy = options.caseId
      options.caseTypeName = caseTypeName
      this.$emit('select', options)
    },

    // 获取案件列表
    getItems() {
      const id = this.userId
      this.$axios
        .get(
          `${this.$base}/lts/case/attrs3?userId=${id}&caseName=${this.caseName}&page=${this.page}&size=${this.size}&isFinish=${this.isFinish}&firstcaseReasonValueId=&nodeId=&shareUserId=&trialGradeValueId=`
        )
        .then(res => {
          let list = []
          if (res.data.data) {
            if (res.data.data.totalElements === 0) {
              // 无数据显示缺省页面
              list = res.data.data.content
              this.isRequest = false
            } else {
              if (res.data.data.content.length > 0) {
                res.data.data.content.forEach((item, index) => {
                  list.push({
                    value: index,
                    label: item.caseName,
                    caseNo: item.caseNo,
                    caseId: item.caseId,
                    createUserId: item.creater.id,
                    acceptCaseTime: item.acceptCaseTime,
                    createUserName: item.creater.nickname,
                    caseTypeValueId: item.caseTypeValueId
                  })
                })
                this.caseList = this.caseList.concat(list)
              }
              if (this.page + 1 >= res.data.data.totalPages) {
                this.isRequest = false
              } else {
                this.isRequest = true
              }
            }
          }
          this.loading = false
          this.refreshing = false
        })
    },

    // 搜索事件
    searchInput() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getItems()
    },

    // 上拉加载事件
    onLoad() {
      this.page++
      this.loading = true
      this.getItems()
    },

    // 下拉刷新
    onRefresh() {
      this.caseList = []
      this.page = 0
      this.isRequest = true
      this.getItems()
    }
  },
  mounted() {
    this.getItems()
  },
  created() {
    this.caseIdCopy = this.caseId
  }
}
</script>

<style lang="stylus">
.case-list-comp
  position relative
  padding-bottom 10px
  box-sizing border-box
  max-height 400px
  overflow scroll
  & .title, .popup-item__option
    text-align center
    height 40px
    line-height 40px
    font-size 16px
    &.active
      color #1989fa
  & .title
    color rgba(69, 90, 100, 0.6)
</style>
